import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { BackgroundText, ContentWithLink, Logos, Spacer, Team, Text, WhatWeDo, WordsHero } from "components";
import { team } from "config";
import about from "images/about.jpg";
import branding from "images/buttons-list/branding.png";
import design from "images/buttons-list/design.png";
import development from "images/buttons-list/development.png";
import fusebox from "images/client-logos/fusebox.svg";
import gpd from "images/client-logos/gpd.svg";
import insanelab from "images/client-logos/insanelab.svg";
import smashing from "images/client-logos/smashing.svg";
import thecamels from "images/client-logos/thecamels.svg";
import tnbt from "images/client-logos/tnbt.svg";
import wpserved from "images/client-logos/wpserved.svg";
import wptavern from "images/client-logos/wptavern.png";
import wupwt from "images/client-logos/wupwt.png";
export const _frontmatter = {
  "title": "About us",
  "navBarColor": "dark"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <WordsHero allowedMoves={6} description="We are a software house <strong>creating value</strong> for the digital age" errorMessage="You just broke the internet, please come back in 2 minutes after we fix it" timeout={120} words={["Design.", "Apps.", "Plugins."]} mdxType="WordsHero" />
    <ContentWithLink button={{
      children: "What we do",
      to: "#what-we-do"
    }} content="Behind the Deparment of Web stands our BracketSpace team. We are experienced WordPress developers who creates products for own store but also for our clients." headline="Beyond the **bracket,** making some **space**" headlineAlign="left" image={{
      src: about,
      title: "about"
    }} imagePosition="right" mdxType="ContentWithLink" />
    <Spacer height={110} responsive={{
      md: 80,
      sm: 60
    }} mdxType="Spacer" />
    <BackgroundText offsetX={44} offsetY={-220} position="right" textAlign="right" mdxType="BackgroundText">
	WORD PRESS
    </BackgroundText>
    <Logos logos={[{
      image: gpd,
      title: "Good People Digital"
    }, {
      image: insanelab,
      title: "InsaneLab"
    }, {
      image: thecamels,
      title: "The Camels"
    }, {
      image: wpserved,
      title: "WP Served"
    }, {
      image: fusebox,
      title: "Fusebox"
    }, {
      image: tnbt,
      title: "TNBT"
    }, {
      image: wupwt,
      title: "Wojewódzki Urząd Pracy w Toruniu"
    }]} logosAlign="center" title="Over 265+ happily delivered projects" titleAlign="left" mdxType="Logos" />
    <BackgroundText offsetX={-30} offsetY={-120} position="left" textAlign="left" responsive={{
      md: {
        offsetY: 60
      },
      sm: {
        offsetY: 80
      }
    }} mdxType="BackgroundText">
	SPECI ALISTS
    </BackgroundText>
    <Logos limitSize={false} logos={[{
      image: smashing,
      title: "Smashing Magazine"
    }, {
      image: wptavern,
      title: "WP Tavern"
    }]} logosAlign="right" title="Featured in" titleAlign="right" titleMargin="small" mdxType="Logos" />
    <Spacer height={87} responsive={{
      md: 50,
      sm: 40
    }} mdxType="Spacer" />
    <Text align="center" tag="h2" color="#110D29" mdxType="Text">
	You've never seen any **Department** working as&nbsp;we&nbsp;do
    </Text>
    <wbr id="what-we-do" />
    <Spacer height={73} mdxType="Spacer" />
    <WhatWeDo items={[{
      buttonLabel: "Branding",
      image: {
        src: branding,
        title: "Branding"
      },
      text: "You won't get just branding. You get the whole discovery process, brainstorming, and new ideas. Final branding is just the cherry on the top."
    }, {
      buttonLabel: "Web design",
      image: {
        src: design,
        title: "Web design"
      },
      text: "We do design that you'll want to turn in a poster  and hang it in your office so everyone on the Zoom call could see it. It won't only look good, it will work as well."
    }, {
      buttonLabel: "Development",
      image: {
        src: development,
        title: "Development"
      },
      text: "We are green developers, because our Github checks are always green. Yes, we do check our code, do internal review and make sure it's tech-debt free during the entire process."
    }]} text="<strong>Awesome</strong> things. This is what we do." title="What we do" mdxType="WhatWeDo" />
    <Spacer height={120} responsive={{
      md: 80,
      sm: 60
    }} mdxType="Spacer" />
    <Text align="center" tag="h3" color="#110D29" mdxType="Text">
	**Meet our team**
    </Text>
    <wbr id="team" />
    <Team people={team.filter(member => member.active)} mdxType="Team" />
    <Spacer height={140} responsive={{
      md: 120,
      sm: 90
    }} mdxType="Spacer" />
    <BackgroundText offsetX={24} offsetY={-330} position="right" textAlign="right" responsive={{
      md: {
        offsetX: 14,
        offsetY: -100
      },
      sm: {
        offsetX: 14,
        offsetY: 70
      }
    }} mdxType="BackgroundText">
	OUR VALUES
    </BackgroundText>
    <ContentWithLink button={{
      children: "Why us",
      to: "/our-work"
    }} content="The Department of Web is a software house cooking delicious products based on WordPress or Laravel broth. We&nbsp;can add a bit of React for extra flavor." headline="We **create** software that&nbsp;matters." headlineAlign="left" mdxType="ContentWithLink" />
    <Spacer height={120} responsive={{
      md: 100,
      sm: 90
    }} mdxType="Spacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      